import { CssBaseline, LinearProgress, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import GlobalErrorBoundary from 'GlobalErrorBoundary';
import { Amplify } from 'aws-amplify';
import { AppLayout, LayoutSkeleton, PopRouteWrapper } from 'components';
import config from 'config';
import { AuthContextProvider } from 'features/auth';
import { ExperimentsLayout } from 'features/experiments';
import { PlatformGuard, ProjectContextProvider } from 'features/project';
import { VariantsContextProvider } from 'features/variants';
import { queryClient } from 'lib/react-query';
import { SnackbarProvider } from 'notistack';
import FeatureSetListRoute from 'pages/projects/project/feature-sets';
import CreateFeatureSetRoute from 'pages/projects/project/feature-sets/add';
import EditFeatureSetRoute from 'pages/projects/project/feature-sets/edit';
import FeatureSetRoute from 'pages/projects/project/feature-sets/page';
import FeaturesListRoute from 'pages/projects/project/features';
import FlowsRoute from 'pages/projects/project/flows';
import FlowRoute from 'pages/projects/project/flows/flow';
import ActiveExperimentsRoute from 'pages/projects/project/flows/flow/experiments/active';
import VariantConfigRoute from 'pages/projects/project/flows/flow/experiments/config';
import ExperimentRoute from 'pages/projects/project/flows/flow/experiments/experiment';
import ExperimentsHistoryRoute from 'pages/projects/project/flows/flow/experiments/history';
import VariantRoute from 'pages/projects/project/flows/flow/experiments/variant';
import ReviewRoute from 'pages/projects/project/flows/flow/review';
import ReviewConfigRoute from 'pages/projects/project/flows/flow/review/config';
import ProductsListRoute from 'pages/projects/project/products';
import TemplatesParentRoute from 'pages/projects/project/templates';
import AddTemplateRoute from 'pages/projects/project/templates/add';
import ArchivedTemplatesListRoute from 'pages/projects/project/templates/archive';
import ArchivedTemplateRoute from 'pages/projects/project/templates/archive/template';
import EditArchivedTemplateRoute from 'pages/projects/project/templates/archive/template/edit';
import ParametersListRoute from 'pages/projects/project/templates/parameters';
import ParametersRoute from 'pages/projects/project/templates/parameters/template';
import EditParametersRoute from 'pages/projects/project/templates/parameters/template/edit';
import Screens from 'pages/projects/project/templates/screens';
import ScreenRoute from 'pages/projects/project/templates/screens/template';
import EditScreenRoute from 'pages/projects/project/templates/screens/template/edit';
import VersionsListRoute from 'pages/projects/project/versions';
import UsersRoute from 'pages/users/index';
import EditUserRoute from 'pages/users/edit';
import React, { Suspense } from 'react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Titled } from 'react-titled';
import theme from 'theme';
import { Platform } from 'types';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.REGION,
    userPoolId: config.COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: config.COGNITO_DOMAIN,
      scope: ['email', 'openid'],
      redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT,
      responseType: 'code',
    },
  },
});

const AppProviders: React.FC = () => (
  <QueryParamProvider adapter={ReactRouter6Adapter}>
    <Titled title="Testania">
      <AuthContextProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Outlet />
        </ThemeProvider>
      </AuthContextProvider>
    </Titled>
  </QueryParamProvider>
);

const ProjectProviders: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <GlobalErrorBoundary>
      <SnackbarProvider autoHideDuration={1000} maxSnack={5} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
        <Suspense
          fallback={
            <LayoutSkeleton>
              <LinearProgress sx={{ mx: -4 }} />
            </LayoutSkeleton>
          }
        >
          <ProjectContextProvider>
            <AppLayout>
              <Suspense>
                <Outlet />
              </Suspense>
            </AppLayout>
          </ProjectContextProvider>
        </Suspense>
      </SnackbarProvider>
    </GlobalErrorBoundary>
    <ReactQueryDevtools />
  </QueryClientProvider>
);

const router = createBrowserRouter([
  {
    element: <AppProviders />,
    children: [
      {
        path: '/projects',
        element: <ProjectProviders />,
        children: [
          {
            path: ':projectId',
            children: [
              { index: true, element: <Navigate to="flows" replace /> },
              {
                path: 'users',
                element: <UsersRoute />,
                children: [
                  {
                    path: ':userId/edit',
                    element: <EditUserRoute />,
                  },
                ],
              },
              {
                path: 'flows',
                element: (
                  <PlatformGuard platform={Platform.Web}>
                    <Titled title={(title) => `Flows | ${title}`}>
                      <Outlet />
                    </Titled>
                  </PlatformGuard>
                ),
                children: [
                  { index: true, element: <FlowsRoute /> },
                  {
                    path: ':flowId',
                    element: (
                      <PopRouteWrapper>
                        <FlowRoute />
                      </PopRouteWrapper>
                    ),
                    children: [
                      {
                        path: 'segments/:segmentId',
                        children: [
                          { index: true, element: <Navigate to="experiments" replace /> },
                          {
                            path: 'experiments',
                            element: (
                              <Titled title={(title) => `Experiments | ${title}`}>
                                <ExperimentsLayout />
                              </Titled>
                            ),
                            children: [
                              { index: true, element: <Navigate to="active" replace /> },
                              {
                                path: 'active',
                                element: <ActiveExperimentsRoute />,
                                children: [{ path: ':experimentId', element: <ExperimentRoute /> }],
                              },
                              {
                                path: 'history',
                                element: <ExperimentsHistoryRoute />,
                                children: [{ path: ':experimentId', element: <ExperimentRoute /> }],
                              },
                            ],
                          },

                          {
                            path: 'review',
                            element: (
                              <VariantsContextProvider>
                                <ReviewRoute />
                              </VariantsContextProvider>
                            ),
                            children: [{ path: 'configs/:configId', element: <ReviewConfigRoute /> }],
                          },
                          { path: 'draft', element: <div>Draft</div> },
                        ],
                      },
                    ],
                  },
                  {
                    path: ':flowId/segments/:segmentId/experiments/:experimentId/variants/:variantId',
                    element: (
                      <VariantsContextProvider>
                        <PopRouteWrapper>
                          <VariantRoute />
                        </PopRouteWrapper>
                      </VariantsContextProvider>
                    ),
                    children: [{ path: 'configs/:configId', element: <VariantConfigRoute /> }],
                  },
                ],
              },
              {
                path: 'templates',
                element: (
                  <PlatformGuard platform={Platform.Web}>
                    <Titled title={(title) => `Templates | ${title}`}>
                      <TemplatesParentRoute />
                    </Titled>
                  </PlatformGuard>
                ),
                children: [
                  { index: true, element: <Navigate to="screens" replace /> },
                  {
                    path: 'screens',
                    element: <Screens />,
                    children: [
                      {
                        path: ':screenId',
                        element: (
                          <PopRouteWrapper>
                            <ScreenRoute />
                          </PopRouteWrapper>
                        ),
                      },
                    ],
                  },
                  {
                    path: 'parameters',
                    element: <ParametersListRoute />,
                    children: [
                      {
                        path: ':screenId',
                        element: (
                          <PopRouteWrapper>
                            <ParametersRoute />
                          </PopRouteWrapper>
                        ),
                      },
                    ],
                  },
                  {
                    path: 'archive',
                    element: <ArchivedTemplatesListRoute />,
                    children: [
                      {
                        path: ':screenId',
                        element: (
                          <PopRouteWrapper>
                            <ArchivedTemplateRoute />
                          </PopRouteWrapper>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'templates',
                element: (
                  <PlatformGuard platform={Platform.Web}>
                    <Titled title={(title) => `Templates | ${title}`}>
                      <Outlet />
                    </Titled>
                  </PlatformGuard>
                ),
                children: [
                  {
                    path: 'screens',
                    children: [
                      {
                        path: ':screenId/edit',
                        element: (
                          <PopRouteWrapper>
                            <EditScreenRoute />
                          </PopRouteWrapper>
                        ),
                      },
                    ],
                  },
                  {
                    path: 'parameters',
                    children: [
                      {
                        path: ':screenId/edit',
                        element: (
                          <PopRouteWrapper>
                            <EditParametersRoute />
                          </PopRouteWrapper>
                        ),
                      },
                    ],
                  },
                  {
                    path: 'archive',
                    children: [
                      {
                        path: ':screenId/edit',
                        element: (
                          <PopRouteWrapper>
                            <EditArchivedTemplateRoute />
                          </PopRouteWrapper>
                        ),
                      },
                    ],
                  },
                  { path: 'add', element: <AddTemplateRoute /> },
                ],
              },
              { path: 'products', element: <ProductsListRoute /> },
              { path: 'features', element: <FeaturesListRoute /> },
              {
                path: 'versions',
                element: (
                  <PlatformGuard platform={Platform.Web}>
                    <VersionsListRoute />
                  </PlatformGuard>
                ),
              },
              {
                path: 'feature-sets',
                element: <FeatureSetListRoute />,
                children: [
                  { path: 'add', element: <CreateFeatureSetRoute /> },
                  { path: ':featureSetId', element: <FeatureSetRoute /> },
                  { path: ':featureSetId/edit', element: <EditFeatureSetRoute /> },
                ],
              },
            ],
          },
        ],
      },
      { path: '*', element: <Navigate to="/projects" replace /> },
    ],
  },
]);

const App = () => (
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={<div>Houston, we have a problem loading</div>} />
  </React.StrictMode>
);

export default App;
