import { CognitoUser } from '@aws-amplify/auth';
import { createContext, useContext } from 'react';

export type AuthStateType = 'unauthenticated' | 'authenticated' | 'inProgress';

export interface AuthStateValue {
  user: CognitoUser | null;
  state: AuthStateType;
  roles: Roles;
}

export interface Roles {
  isAdmin: boolean;
  isEditor: boolean;
}

export interface AuthContextType extends AuthStateValue {
  // signOut: () => any;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function useAuth() {
  return useContext(AuthContext);
}
