import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AuthContext, AuthStateType, Roles } from 'features/auth';
import { CognitoUser } from '@aws-amplify/auth';
import { Auth, Hub } from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router';

interface AuthContextProviderProps {
  children: ReactNode;
}

const AuthContextProvider: FC<AuthContextProviderProps> = ({ children }) => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [state, setState] = useState<AuthStateType>('unauthenticated');
  const [roles, setRoles] = useState<Roles>({
    isAdmin: false,
    isEditor: false,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const init = useCallback(async () => {
    try {
      const u = await Auth.currentAuthenticatedUser();
      const groups = u.getSignInUserSession()?.getIdToken().payload['cognito:groups'];
      setRoles({
        isAdmin: groups?.includes('admin') ?? false,
        isEditor: groups?.includes('editor') ?? false,
      });

      setUser(u);
      setState('authenticated');
    } catch (err) {
      console.error(err);
      // @ts-ignore we can safely ignore lack of 'provider', due to use of documented signature
      Auth.federatedSignIn({ customState: JSON.stringify(location) });
    }
  }, [location]);

  useEffect(() => {
    init();

    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn': {
          const groups = data.signInUserSession.getIdToken().payload['cognito:groups'];
          setRoles({
            isAdmin: groups?.includes('admin') ?? false,
            isEditor: groups?.includes('editor') ?? false,
          });

          setUser(data);
          break;
        }
        case 'signOut': {
          setUser(null);
          break;
        }
        case 'customOAuthState': {
          try {
            const prevLocation = JSON.parse(data);
            navigate(prevLocation);
          } catch (_) {
            // do nothing :)
          }
          break;
        }
        default:
          break;
      }
    });

    return unsubscribe;
  }, [navigate, init]);

  const contextValue = useMemo(
    () => ({
      user,
      roles,
      state,
    }),
    [user, state, roles]
  );

  if (state === 'unauthenticated') {
    return null;
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
