import { Auth } from 'aws-amplify';
import { isAxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { SentryErrorTypes } from 'lib/sentry';
import { FC, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowBackIosNew, SpaceDashboard } from '@mui/icons-material';
import { Box, Link, Button, Fade, Stack, Typography, styled } from '@mui/material';
import { projectQueryKeys, Project } from 'features/project';
import { FallbackProps } from 'react-error-boundary';
import { queryClient } from 'lib/react-query';
import SlicedText from './SlicedText';

const MainWrapper = styled(Box)`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type AxiosErrorResponseData = { message?: string; error?: string };

const GlobalErrorBoundaryFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const projects = queryClient.getQueryData<Project[]>(projectQueryKeys.list());
  const hasAvaliableProjects = !!projects?.length;
  const isZeroProjectAssigned = projects?.length === 0;

  const errorLocation = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      // Reset by clicking browser navigation buttons
      resetErrorBoundary();
    }
  }, [location, resetErrorBoundary]);

  const doesAnyHistoryEntryExist = location.key !== 'default';

  const errorData = useMemo(() => {
    if (isAxiosError<AxiosErrorResponseData>(error)) {
      const { response } = error;
      const statusCode = response?.status ?? 500;
      const errorMessage = response?.data?.message ?? response?.data?.error ?? 'Server error';

      return {
        statusCode,
        mesage: errorMessage,
      };
    }

    Sentry.captureException(error, {
      tags: { type: SentryErrorTypes.Runtime },
    });

    return {
      statusCode: 500,
      mesage: 'Something went wrong :(',
    };
  }, [error]);

  const handleReset = () => {
    resetErrorBoundary();
  };

  const signOut = async () => {
    await Auth.signOut();
    window.location.reload();
  };

  const goBack = () => {
    navigate(-1);
    resetErrorBoundary();
  };

  if (errorData.statusCode === 401) {
    // Spefic case when page wasn't reload automatically
    window.location.reload();
    return null;
  }

  if (errorData.statusCode === 403) {
    return (
      <MainWrapper>
        <Fade in timeout={500}>
          <Box mt={-10} textAlign="center">
            {(() => {
              // Case 1
              // A user doesn't have access to the SELECTED project, but others are still available.
              if (hasAvaliableProjects) {
                return (
                  <Typography mt={2} maxWidth={500} variant="h6" component="h1">
                    You do not have access to the current project, try switching to{' '}
                    {projects.map((prj, index) => (
                      <>
                        <Link component={RouterLink} to={`/projects/${prj.id}`}>
                          {prj.name}
                        </Link>
                        {index < projects.length - 1 && ', '}
                      </>
                    ))}
                  </Typography>
                );
              }
              if (isZeroProjectAssigned) {
                // Case 2
                // The user doesn't have any assigned projects
                return (
                  <Typography mt={2} maxWidth={500} variant="h6" component="h1">
                    You do not have any projects assigned to you.
                  </Typography>
                );
              }
              // Case 3
              // The user's role is below the Editor, the projects gets 403 error also
              return (
                <Typography maxWidth={500} variant="h6" component="h1">
                  We`re glad to see you here. Don`t hesitate to contact the{' '}
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href="https://welltech.freshservice.com/support/catalog/items/75"
                  >
                    IT Help Desk
                  </Link>{' '}
                  to attain access to Testania Nuevo.
                </Typography>
              );
            })()}

            <Button sx={{ mt: 2 }} variant="outlined" onClick={signOut}>
              Sign out
            </Button>
          </Box>
        </Fade>
      </MainWrapper>
    );
  }

  return (
    <MainWrapper>
      <Fade in timeout={500}>
        <Box mt={-10} textAlign="center">
          <Typography mb={-5} variant="h5" component="h1">
            {errorData.mesage}
          </Typography>
          <SlicedText text={errorData.statusCode} />
          <Stack mt={-5} gap={2} flexDirection="row" justifyContent="center">
            {doesAnyHistoryEntryExist ? (
              <Button
                startIcon={<ArrowBackIosNew />}
                variant="outlined"
                size="large"
                color="secondary"
                onClick={goBack}
              >
                Go back
              </Button>
            ) : (
              <Button
                startIcon={<SpaceDashboard />}
                variant="outlined"
                size="large"
                color="secondary"
                onClick={() => navigate('/')}
              >
                Home
              </Button>
            )}
            <Button variant="contained" size="large" color="secondary" onClick={handleReset}>
              Reload page
            </Button>
          </Stack>
        </Box>
      </Fade>
    </MainWrapper>
  );
};

export default GlobalErrorBoundaryFallback;
