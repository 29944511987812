import {
  AppBar,
  Toolbar,
  Tooltip,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  styled,
  Divider,
  useTheme,
  useMediaQuery,
  ThemeProvider,
  Skeleton,
  ListItemText,
} from '@mui/material';
import { AccountCircle, Menu as BurgerIcon, Close as CloseIcon } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import { FC, Suspense, useState } from 'react';
import { darkSubTheme } from 'theme';
import { useAuth } from 'features/auth/AuthContext';
import { ProjectSelect, useProject } from 'features/project';
import { getUserNameByEmail } from 'utils';
import getProjectPagePath from 'pages/projects/project/getProjectPathPath';
import { Link } from 'react-router-dom';

const NavbarRoot = styled(AppBar)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    background-color: ${({ theme }) => theme.palette.background.paper};
    padding-left: ${({ theme }) => theme.sizes.sidebarWidth}px;
  }
`;

interface NavbarProps {
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const Navbar: FC<NavbarProps> = ({ isDrawerOpen, openDrawer, closeDrawer }) => {
  const project = useProject();
  const { user, roles } = useAuth();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignOut = async () => {
    await Auth.signOut();
    handleCloseUserMenu();
  };

  const defaultTheme = useTheme();
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down('md'));

  const email = user?.getSignInUserSession()?.getIdToken().payload.email;
  const fullName = getUserNameByEmail(email);

  return (
    <ThemeProvider theme={isMobile ? darkSubTheme : defaultTheme}>
      <NavbarRoot position="fixed" elevation={0}>
        <Toolbar>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                display: 'block',
              },
              display: 'none',
            })}
          >
            {isDrawerOpen ? (
              <IconButton key="drawer_opener" onClick={closeDrawer}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton key="drawer_opener" onClick={openDrawer}>
                <BurgerIcon />
              </IconButton>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Suspense fallback={<Skeleton variant="rectangular" height={36} width={200} />}>
              <ProjectSelect />
            </Suspense>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} aria-controls="menu-appbar" aria-haspopup="true">
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                <ListItemText primary={fullName} secondary={user?.getSignInUserSession()?.getIdToken().payload.email} />
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => handleCloseUserMenu()}
                to={`${getProjectPagePath(project.id)}/users`}
              >
                {roles.isAdmin && <Typography textAlign="center">Users</Typography>}
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleSignOut}>
                <Typography textAlign="center">Sign Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
        <Divider />
      </NavbarRoot>
    </ThemeProvider>
  );
};

export default Navbar;
